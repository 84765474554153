@import "variables";

@mixin activeButtonDark {
  background-color: $primary-dark;
  color: $primary-light;
}

.btn {
  display: flex;
  width: 180px;
  height: 40px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-transform: uppercase;
  background-color: $primary-light;
  color: $primary-dark;
  border: none;
  outline: none;
  -webkit-transition: opacity 0.5s ease-out;
  -moz-transition: opacity 0.5s ease-out;
  -o-transition: opacity 0.5s ease-out;
  transition: opacity 0.5s ease-out;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &--dark,
  &--active {
    @include activeButtonDark;
  }

  &--border-bottom {
    color: $medium-grey;
    border-bottom: 1px solid $primary-dark;
  }

  &--small {
    font-size: 12px;
    text-transform: none;
  }

  &--buy {
    margin: 15px auto;
    background-color: $light-grey;
  }

  &--edit {
    margin: 15px auto;
    background-color: $light-grey;
    font-size: 12px;
    width: 100px;
    height: 30px;
  }
}

a.btn {
  text-decoration: none;
}

.text-input {
  input {
    border: none;
    border-bottom: 1px solid $primary-dark;
    margin-bottom: 1px;
    outline: none;

    &:focus {
      border-width: 2px;
      margin-bottom: 0px;
    }
  }

  &__label {
    font-size: 12px;
    color: $medium-grey;
    padding: 5px 0;
  }

  &--big {
    input {
      background-color: rgba(255, 255, 255, 0);
      font-size: 20px;
      color: $white;
      border-color: $white;
    }
  }
}

.submit-wrapper {
  height: 180px;
  align-items: center;
  justify-content: space-between;

  &__info-box {
    width: 150px;
    margin-right: auto;
  }
}

.select-wrapper {
  //width: 73%;
  width: 48%;

  &__label {
    text-transform: uppercase;
  }

  &.negative-margin {
    margin-top: -17px;
  }

  &.last-quarter {
    margin-top: -17px;
    width: 23%;
  }

  &.license-length-select {
    font-size: 16px;
    .react-select__single-value {
      top: 75%
    }
    .react-select__menu {
      font-size: 12px;
    }
    .react-select__value-container {
      padding-left: 0;
    }
  }
}

.message {
  &--error {
    font-size: 12px;
    color: #ee0022;
  }

  &__alert {
    font-size: 20px;
    color: #ee0022;
    text-align: center;
  }
}

.add-song {
  display: inline-block;
  font-size: 13px;
  color: $medium-grey;
  cursor: pointer;
  -webkit-transition: color 0.5s ease-out;
  -moz-transition: color 0.5s ease-out;
  -o-transition: color 0.5s ease-out;
  transition: color 0.5s ease-out;

  &:hover {
    color: $medium-grey;
  }
}

.edit-license {
  margin-top: 15px;

  .text-inputs-wrapper {
    display: block;

    .text-input {
      width: 100%;
      margin: 0;
    }
  }
}

.quarter-picker {
  width: 23%;

  &--label {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .react-date-picker {
    width: 100%;

    .react-date-picker__wrapper {
      margin-top: 9px;
    }
  }
}

.success-text {
  font-size: 28px;
  color: #7ed957;
  margin: 20px auto;
  text-align: center;
  font-weight: bold;
}

table {
  border-collapse: collapse;

  th, td {
    padding: 10px 20px;
    text-align: center;
    border-bottom: 1px solid $medium-grey;
  }
}

.switch-wrapper {
  margin-top: 40px;

}

/*
 * radio as button
 */
input[type='radio'] {
  display: none;
}

input[type='radio']:checked + label.btn {
  @include activeButtonDark;
}

// todo: przerobić na BEM
.db-radio-wrapper {
  align-self: center;
  font-size: 10px;
  //margin-top: -13px;
  .switch-radio {
    width: 60px;
    border-radius: 2px;
    font-weight: bold;
    border: 1px solid $light-grey;
    text-align: center;
    cursor: pointer;
    margin-left: 10px;
    &:hover {
      background-color: $lighter-grey;
    }
  }
  input[type='radio']:checked + label.switch-radio {
    background-color: $light-grey;
  }
}

/*
 *  Media-queries
 */

@media screen and (max-width: 768px) {
  .select-container {
    flex-wrap: wrap;
  }

  .select-wrapper, .select-wrapper.last-quarter {
    width: 48%;
  }

  .select-exp {
    width: 100%;
    margin-bottom: 50px;
  }

  .song-wrapper {
    .text-input {
      width: 31%;
      margin-right: 1%;
    }
  }

  .quarter-picker {
    width: 48%;
  }
}

@media screen and (max-width: 500px) {
  .select-container, .info-container {
    flex-direction: column;
    margin-top: 0px;
    padding-right: 2%;

    .select-wrapper, .text-input {
      width: 100%;
      margin-top: 25px;
      margin-bottom: 0;

      .react-select__multi-value {
        font-size: 18px;

        &__remove {
          width: 40px;

          svg {
            margin: auto;
          }
        }
      }
    }
  }

  .quarter-picker {
    width: 100%;
    margin-top: 50px;
    height: 50px;
  }
}

