@import "variables";

.alert-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(245, 245, 245, 0.8);
  z-index: 100;
  &__window {
    position: absolute;
    width: 300px;
    min-height: 100px;
    border-radius: 5px;
    top: 10vh;
    left: calc(50% - 150px);
    border-color: $lighter-grey;
    opacity: 0.8;
    color: #ffffff;
    &--title {
      text-align: center;
      padding: 10px;
      font-size: 20px;
      font-weight: bold;
    }
    &--body {
      text-align: center;
      padding: 20px;
    }
  }

  &__close-btn {
    margin: 15px auto;
  }
}
