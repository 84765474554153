@import "variables";

* {
  font-size: 100%;
  font-family: Arial;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: $white;
}

.spinner {
  width: 30px;
}

.none {
  display: none;
}

.block {
  display: block;
}

.flex {
  display: flex;

  &--column {
    flex-direction: column;
  }

  &--column-align-bottom {
    flex-direction: column;
    justify-content: flex-end;
  }

  &--1 {
    flex: 1;
  }

  &--2 {
    flex: 2;
  }

  &--wrap {
    flex-wrap: wrap;
  }
}

.app-container {
  display: flex;
  height: 100vh;
  //width: 70%;
  margin: auto;

  &__left-col {
    position: relative;
    width: 65%;
    //padding: 50px 30px;
    padding: 50px 2%;
    overflow-y: scroll;
  }

  &__right-col {
    width: 35%;
    //padding: 50px;
    padding: 3%;
    background-image: url("../images/earth_1.jpg");
    background-size: cover;
  }
}

.quarter-col, .btn.quarter-col {
  width: 23%;

  &--mr {
    width: 23%;
    margin-right: 2%;
  }
}

.half-col {
  width: 48%;

  &--mr {
    width: 48%;
    margin-right: 2%;
  }
}

.text {
  font-size: 12px;
  color: $primary-dark;

  &__header {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__form-title {
    font-size: 16px;
  }

  &--dark {
    color: #000;
  }

  &--primary {
    color: $primary-light;
  }
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.asterix-wrapper {
  margin-top: 50px;
  font-size: 12px;
  font-style: italic;

  .text {
    padding-top: 15px;
    border-top: 1px solid $light-grey;
  }
}

.catalogs-links {
  font-size: 14px;
  font-weight: bold;
  margin-top: 70px;
  padding-top: 15px;
  border-top: 1px solid $light-grey;

  ul {
    font-size: 17px;
    font-weight: normal;
    margin: 0;
    padding-left: 17px;
    text-transform: uppercase;
    list-style-type: circle;

    li.link {
      margin: 7px 0;

      a {
        color: $primary-dark;

        &:hover {
          color: $medium-grey;
        }
      }
    }
  }
}

/*
 *  Responsywna tabelka
 */

table.responsive {
  tr:nth-child(odd) {
    background-color: $lighter-grey;
  }
}

table.single-row {
  width: 100%;
  max-width: 555px;
  margin: auto;

  tr {
    &:nth-child(even) {
      background-color: $lighter-grey;
    }
  }

  td {
    text-align: right;
  }

  td:first-child {
    text-align: left;
    font-weight: bold;
  }
}


/*
 *  Marginesy/paddingi
 */

.mt-5 {
  margin-top: 5px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.ml-10 {
  margin-left: 10px;
}

.v-middle {
  display: inline-block;
  vertical-align: middle;
}

.expand-100 {
  width: 100%;
}

/*
 *  Style dla placeholderów
 */

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #777777;
  font-size: 10px;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 10px;
  color: #777777;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-size: 10px;
  color: #777777;
}


/*
 *  Zmiana styli autocomplete
 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 10px #FFF inset;
}

/*
 *  Media-queries
 */

@media screen and (max-width: 992px) {
  .app-container {
    display: block;

    &__left-col {
      padding-left: 2%;
      padding-right: 0%;
    }

    &__left-col, &__right-col {
      box-sizing: border-box;
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .quarter-col, .btn.quarter-col {
    width: 48%;

    &--mr {
      width: 48%;
      margin-bottom: 20px;
    }
  }

  .app-container {
    display: block;

    &__left-col {
      padding-left: 6%;
      padding-right: 4%;
    }
  }

  table.responsive {
    width: 100%;

    tr {
      display: block;
      margin-top: 50px;

      &:nth-child(odd) {
        background-color: unset;
      }
    }

    td {
      display: block;
      text-align: right;

      &:nth-child(even) {
        background-color: $lighter-grey;
      }

      &:before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
      }
    }

    th {
      display: none;
    }
  }
}

//@media screen and (max-width: 500px) {
//  .quarter-col, .btn.quarter-col {
//    width: 100%;
//
//    &--mr {
//      width: 100%;
//      margin-bottom: 20px;
//    }
//  }
//}
